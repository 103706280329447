<template>
    <div class="my-auto cursor-pointer" @click="$router.push('home')" :class="windowWidth < 600 ? 'px-2' : ''">
        <img :src="data.Logo" v-if="!transition" :width="windowWidth < 600 ? data.LogoMobileWidth : data.LogoWidth" alt="">
        <img :src="data.LogoTransition" v-else :width="windowWidth < 600 ? data.LogoMobileTransitionWidth : data.LogoTransitionWidth" alt="">
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        },
        transition: {
            type: Boolean
        }
    }
}
</script>
